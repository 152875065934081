/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "En un principio llegué a Bash, la shell eterna, instalada por defecto en la gran mayoría de distribuciones. Poco a poco le fui cogiendo el gusto a la línea de comandos, ciertas cosas me costaban mucho menos hacer por consola que tirando de ratón e interfaces gráficas, el historial era mi amigo, hasta ", React.createElement(_components.a, {
    href: "https://www.linux.com/learn/how-make-fancy-and-useful-bash-prompt-linux"
  }, "me tuneé el promt"), " para tener uno más útil:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token assign-left variable\"><span class=\"token environment constant\">PS1</span></span><span class=\"token operator\">=</span><span class=\"token string\">\"\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[01;32m\\]\\u@\\h\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[00m\\]:\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[01;34m\\]\\W\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[00m\\]\"</span> <span class=\"token assign-left variable\"><span class=\"token environment constant\">PS1</span></span><span class=\"token operator\">=</span><span class=\"token string\">\"<span class=\"token environment constant\">$PS1</span> \\`if [ \\<span class=\"token variable\">$?</span> = 0 ]; then echo -e '\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[01;32m\\]:-)';\"</span> <span class=\"token assign-left variable\"><span class=\"token environment constant\">PS1</span></span><span class=\"token operator\">=</span><span class=\"token string\">\"<span class=\"token environment constant\">$PS1</span> else echo -e '\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[01;31m\\]:-(' \\<span class=\"token variable\">$?</span>; fi\\`\\[<span class=\"token entity\" title=\"\\033\">\\033</span>[00m\\]\"</span> <span class=\"token assign-left variable\"><span class=\"token environment constant\">PS1</span></span><span class=\"token operator\">=</span><span class=\"token string\">\"<span class=\"token environment constant\">$PS1</span> \\<span class=\"token variable\"><span class=\"token variable\">$(</span>__git_ps1 <span class=\"token punctuation\">\\</span>\"<span class=\"token punctuation\">(</span>%s<span class=\"token punctuation\">)</span><span class=\"token punctuation\">\\</span>\"<span class=\"token variable\">)</span></span> \\$ \"</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Empecé a hacer pequeños scripts para automatizar mi trabajo de forma autodidacta, pero me di cuenta de que me faltaba base y aproveché un curso de Bash que me abrió nuevas puertas."), "\n", React.createElement(_components.p, null, "Expect por ejemplo fue todo un descubrimiento, para automatizar no ya solo comandos shell si no también la entrada a aplicaciones, por ejemplo podía lanzar comandos a conexiones ssh o serie, muy interesante para desarrollo de sistemas empotrados."), "\n", React.createElement(_components.p, null, "También descubrí usos curiosos de algunas utilidades de GNU, concretamente ", React.createElement(_components.a, {
    href: "https://bost.ocks.org/mike/make/"
  }, "Make"), ", que tiene una potencia ", React.createElement(_components.a, {
    href: "https://github.com/datamade"
  }, "inesperada"), "."), "\n", React.createElement(_components.p, null, "También os dejo un ", React.createElement(_components.a, {
    href: "https://ryanstutorials.net/bash-scripting-tutorial/"
  }, "tutorial muy completo sobre Bash"), ", para profundizar."), "\n", React.createElement(_components.h3, null, "El cambio a Zsh"), "\n", React.createElement(_components.p, null, "Pero cuando pensaba que ya dominaba la terminal al máximo y le había sacado todo el partido descubrí Zsh y Oh My Zsh, una shell modular que se ha extendido para hacernos la vida aún más fácil."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">apt</span> <span class=\"token function\">install</span> <span class=\"token function\">git</span> <span class=\"token function\">zsh</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sh</span> <span class=\"token parameter variable\">-c</span> <span class=\"token string\">\"<span class=\"token variable\"><span class=\"token variable\">$(</span><span class=\"token function\">curl</span> <span class=\"token parameter variable\">-fsSL</span> https://raw.githubusercontent.com/robbyrussell/oh-my-zsh/master/tools/install.sh<span class=\"token variable\">)</span></span>\"</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">git</span> clone https://github.com/zsh-users/zsh-completions <span class=\"token variable\">${ZSH_CUSTOM<span class=\"token operator\">:=</span>~<span class=\"token operator\">/</span>.oh-my-zsh<span class=\"token operator\">/</span>custom}</span>/plugins/zsh-completions</code></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">git</span> clone https://github.com/zsh-users/zsh-autosuggestions <span class=\"token variable\">${ZSH_CUSTOM<span class=\"token operator\">:-</span>~<span class=\"token operator\">/</span>.oh-my-zsh<span class=\"token operator\">/</span>custom}</span>/plugins/zsh-autosuggestions</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Estuve toqueteando el archivo de configuración"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">nano</span> ~/.zshrc</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y acabé con este tema y estos plugins:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token assign-left variable\">ZSH_THEME</span><span class=\"token operator\">=</span><span class=\"token string\">\"agnoster\"</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token assign-left variable\">plugins</span><span class=\"token operator\">=</span><span class=\"token punctuation\">(</span>git <span class=\"token function\">history</span> per-directory-history pip <span class=\"token function\">sudo</span> zsh-completions zsh-autosuggestions<span class=\"token punctuation\">)</span> autoload <span class=\"token parameter variable\">-U</span> compinit <span class=\"token operator\">&amp;&amp;</span> compinit</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y finalmente me cambié a Zsh:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre class=\"language-text\"><code class=\"language-text\">chsh -s $(which zsh)</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Reinicié la sesión y me sumergí en esta shell por descubrir. Y aquí sigo, descubriendo cada día nuevas formas de hacer mi trabajo más eficientemente. La shell es un mundo más profundo de lo que en un principio podría parecer."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
